import { Ad, AdContainer, AdContainerSize, createComponent, Flex } from 'react-commons';
import Yolla, { YollaType, YollaSubType } from '../Yolla';

import style from './index.module.scss';

export default createComponent('FixedAd', { style }, function FixedAd ({ className }) {
  return (
    <Flex alignCenter justifyCenter className={className}>
      <AdContainer size={AdContainerSize.LEADERBOARD} className='--hideOnMobileDevices'>
        <Yolla type={YollaType.DESKTOP_LEADERBOARD} subType={YollaSubType.DESKTOP_LEADERBOARD} />
      </AdContainer>
      <AdContainer size={AdContainerSize.MOBILE_LEADERBOARD} className='--hideOnDesktopDevices'>
        <Yolla type={YollaType.MOBILE_LEADERBOARD} subType={YollaSubType.MOBILE_LEADERBOARD} />
      </AdContainer>
    </Flex>
  );
});
